<script>
import $ from 'jquery';

export default {
    emits: ['update:modelValue', 'select', 'unselect', 'close'],
    props: {
        select2Method: {
            type: String,
            default: 'jix_select2',
        },
        config: {
            type: Object,
            default() {
                return {};
            },
        },
        modelValue: {
            type: [String, Number, Array],
            default: null,
        },
        /**
        * When true, the select2 will not lock the scrolling of its parent modal
        */
        disableScrollBlock: {
            type: Boolean,
            default: false,
        },
        /**
        * When true, the select2's dropdown will be opened when unselecting an element
        */
        openWhenUnselecting: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ignoreChanges: false,
        };
    },

    mounted() {
        const effectiveConfig = this.getEffectiveConfig();
        const element = $(this.$el)[this.select2Method](effectiveConfig)
            .val(this.modelValue)
            .trigger('change')
            .on('change', () => {
                if (!this.ignoreChanges) {
                    this.$emit('update:modelValue', $(this.$el).val());
                }
            })
            .on('select2:select', (event) => {
                if (!this.ignoreChanges) {
                    this.$emit('select', event.params.data);
                }
            })
            .on('select2:unselect', () => {
                if (!this.ignoreChanges) {
                    this.$emit('unselect');
                }
            })
            .on('select2:close', () => {
                if (!this.ignoreChanges) {
                    this.$emit('close');
                }
            });

        if (this.disableScrollBlock){
            element.on('select2:open', function (e) {
                const event = "scroll.select2";
                $(e.target).parents().off(event);
                $(window).off(event);
            });
        }
        if (!this.openWhenUnselecting){
            element.data('select2').$selection.on('click', '.select2-selection__choice__remove', function(e) {
                e.stopPropagation();
            });
        }
    },

    unmounted() {
        $(this.$el).off().select2('destroy');
    },

    watch: {
        modelValue: {
            deep: true,
            handler() {
                this.syncValue();
            },
        },
        config(config) {
            $(this.$el).empty()[this.select2Method](config);
        },
    },

    methods: {
        syncValue() {
            this.ignoreChanges = true;
            $(this.$el)
                .val(this.modelValue)
                .trigger('change');
            this.ignoreChanges = false;
        },
        /**
         * Focus the select2 and open the dropdown.
         */
        open() {
            $(this.$el).select2('open');
        },
        /**
         * Close the dropdown.
         */
        close() {
            $(this.$el).select2('close');
        },
        /**
         * Focus the select2 without opening the dropdown.
         */
        focus() {
            // this.$el.focus() opens the dropdown.
            // $(this.$el).select2('focus') makes it look like it is focused,
            // but keyboard focus is somewhere else.
            // This does the job:
            this.open();
            this.close();
        },
        getEffectiveConfig() {
            const res = { ...this.config };
            if (this.disableScrollBlock) {
                res.dropdownParent = $(this.$el).parent();
            }
            return res;
        },
    },
};
</script>

<template>
    <select>
        <slot />
    </select>
</template>
